/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import permutation from "../../../images/Combinatorics/permutations.webp";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    em: "em",
    h3: "h3",
    ol: "ol",
    li: "li",
    pre: "pre",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "permutation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#permutation",
    "aria-label": "permutation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Permutation"), "\n", React.createElement(_components.p, null, "and program to find the Permutation in Rust"), "\n", React.createElement(_components.h2, {
    id: "what-is-permutation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-permutation",
    "aria-label": "what is permutation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is Permutation"), "\n", React.createElement(_components.p, null, "Permutation is the number of ways in which some of the objects from a given set can be ", React.createElement(_components.strong, null, "chosen and arranged"), ". In permutation, the order in which things are arranged also matters, unlike in combination."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "For example :"), " Permutation of word RUST are RSTU, RSUT, RTSU, RTUS ..... 24 ways"), "\n", React.createElement(_components.p, null, "If you want to read more about what Permutation means, I would recommend you to read from any High School Mathematics book of your preference."), "\n", React.createElement(_components.p, null, "In this article, we will use standard reference : the number of total objects to be arranged are denoted by ", React.createElement(_components.strong, null, "n"), " and the number of items chosen at a time are denoted by ", React.createElement(_components.strong, null, "r"), "."), "\n", React.createElement(_components.p, null, "So, total number of ways of arranging ", React.createElement(_components.em, null, "n"), " unique items taking ", React.createElement(_components.em, null, "r"), " at a time is written ", React.createElement(_components.strong, null, React.createElement("sup", null, "n"), "P", React.createElement("sub", null, "r")), ". It will also be written as ", React.createElement(_components.strong, null, "P(n, r)")), "\n", "\n", React.createElement("div", {
    style: {
      textAlign: 'center'
    }
  }, React.createElement("img", {
    src: permutation,
    width: "100%",
    alt: "Permutation of RUST"
  })), "\n", React.createElement(_components.h2, {
    id: "permutation-formulae",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#permutation-formulae",
    "aria-label": "permutation formulae permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Permutation formulae"), "\n", React.createElement(_components.h3, {
    id: "when-all-objects-are-distinct",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#when-all-objects-are-distinct",
    "aria-label": "when all objects are distinct permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "When all Objects are distinct"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "When repetition is allowed :"), " If Repetition of an object is allowed, then we can simply write number of permutations = ", React.createElement(_components.strong, null, "n", React.createElement("sup", null, "r")), ". We can calculate it using Exponent or Power function."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "When repetition is not allowed :"), " If Repetition of an object is not allowed, then permutations can be written as  ", React.createElement(_components.strong, null, React.createElement("sup", null, "n"), "P", React.createElement("sub", null, "r"), " = n! / (n-r)!"), " We can calculate it using ", React.createElement(_components.a, {
    href: "combinatorics/factorial-of-number/"
  }, "Factorial function")), "\n"), "\n"), "\n", React.createElement(_components.h3, {
    id: "when-some-objects-are-identical",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#when-some-objects-are-identical",
    "aria-label": "when some objects are identical permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "When some objects are identical"), "\n", React.createElement(_components.ol, {
    start: "3"
  }, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "When repetition is allowed :"), " If Repetition of an object is allowed, then we can simply write number of permutations = ", React.createElement(_components.strong, null, "k", React.createElement("sup", null, "r")), ", where k are total number of unique objects. We can calculate it using Exponent or Power function."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "When repetition is not allowed :"), " If repetition is not allowed, but there are ", React.createElement(_components.em, null, "n", React.createElement("sub", null, "1")), " identical objects of type 1, ", React.createElement(_components.em, null, "n", React.createElement("sub", null, "2")), " objects of type 2 ... ", React.createElement(_components.em, null, "n", React.createElement("sub", null, "k")), " objects of type k, then total permutations of all objects are"), "\n"), "\n", React.createElement("center", null, " n! / n", React.createElement("sub", null, "1"), "! × n", React.createElement("sub", null, "2"), "! × ... n", React.createElement("sub", null, "k"), "!  "), "\n", React.createElement(_components.h2, {
    id: "program-to-find-permutation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#program-to-find-permutation",
    "aria-label": "program to find permutation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Program to find Permutation"), "\n", React.createElement(_components.p, null, "Now, let us write a program in Rust Language to find the number of permutations when we are given ", React.createElement(_components.em, null, "n"), " distinct objects, and we can arrange ", React.createElement(_components.em, null, "r"), " at a time."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn permutation(n: i128, r: i128) -> i128{\n    // nPr = n! / (n-r)!\n    return factorial(n)/factorial(n-r);\n}\n")), "\n", React.createElement(_components.p, null, "With driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "\nfn permutation(n: i128, r: i128) -> i128{\n    // nPr = n! / (n-r)!\n    return factorial(n)/factorial(n-r);\n}\n\n// Driver code\n\nuse std::io::stdin;\nfn take_int() -> i128 {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    return input.trim().parse().unwrap();\n}\nfn factorial(number : i128) -> i128{\n    let mut factorial : i128 = 1;\n    for i in 1..(number+1) { factorial*=i; }\n    return factorial;\n}\n\npub fn main() {\n    let n = take_int();\n    let r = take_int();\n    println!(\"{}\", permutation(n, r));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "6 ", React.createElement("br"), "\n4"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "360"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( n )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Permutation is the number of ways in which some of the objects from a given set can be ", React.createElement(_components.strong, null, "chosen and arranged."), "\nIn this article, we saw the formulae for calculating number of permutation for given n and r and also made a program to find number of permutations in Rust."), "\n", React.createElement(_components.p, null, "Here is the function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn factorial(number : i128) -> i128{\n    let mut factorial : i128 = 1;\n    for i in 1..(number+1) { factorial*=i; }\n    return factorial;\n}\n\nfn permutation(n: i128, r: i128) -> i128{\n    return factorial(n)/factorial(n-r);\n}\n\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Permutations - Rust Programming",
    description: "Permutation is the number of ways in which some of the objects from a given set can be chosen and arranged. We will see permutation formulae and also write a program to find number of permutations in Rust."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
